import {
  IonHeader,
  IonIcon,
  IonMenu,
  IonPage,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  isPlatform,
  IonModal,
} from '@ionic/react'
import { Link, useParams, useLocation, useHistory, Switch, Route } from 'react-router-dom'
import { keyframes } from '@emotion/core'
import { useMedia } from 'use-media'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'

import { AppAvatar } from '../components/AppAvatar'
import { Avaliacao } from '../avaliacao/Avaliacao'
import { BlocoNotas, CadastroNota } from '../bloco-notas'
import { Conteudo } from '../conteudo/Conteudo'
import { Foruns } from '../foruns/Foruns'
import { IStateRedux } from '../common/ReduxStore'
import { ModuloFinalizado } from '../modulos/ModuloFinalizado'
import { Modulos } from '../modulos/Modulos'
import { Perfil } from '../usuarios/Perfil'
import { RegrasAvaliacao } from '../avaliacao/RegrasAvaliacao'
import { ResultadoAvaliacao } from '../avaliacao/ResultadoAvaliacao'
import { Show } from '../common/Show'
import { TrocarSenha } from '../usuarios/TrocarSenha'
import { Tutoria } from '../tutoria/Tutoria'
import { Usuario } from '../usuarios/Usuarios'
import { ValidacaoFacialSerpro } from '../validacao-facial/ValidacaoFacialSerpro'
import { getUsuarioLogado, authActions } from '../auth/AuthStore'
import AnotacoesSvg from '../svgs/notes'
import AtividadesSvg from '../svgs/tasks'
import ForumQuestaoSvg from '../svgs/comment-question'
import HomeSvg from '../svgs/home'
import LivroSvg from '../svgs/book'
import LivrosSvg from '../svgs/books'
import LogoNewDriver from '../assets/icons/logo.svg'
import NewHome from './NewHome'
import Progresso from '../progresso/Progresso'
import Biblioteca from '../biblioteca/Biblioteca'
import ProgressoSvg from '../svgs/progress'
import UsuariosSvg from '../svgs/users'
import closeIcon from '../assets/icons/close.svg'
import menuHamburguer from '../assets/icons/menu-hamburguer.svg'
import { ContextMenu, ContextMenuItem } from '../common/ContextMenu'
import { QuestoesRespondidas } from '../progresso/QuestoesRespondidas'
import { Button, ModalIniciarTutorial } from '../components'
import { useCursoAtual } from '../cursos/CursosStore'

import { logoutZenDesk, useOpenZenDeskChat } from '../common/hooks/useZenDeskApi'
import { Tutorial } from './Tutorial'
import { GlobalStore } from '../GlobalStore'

import { TermosUso, PoliticaPriv } from './Burocracias'
import { Button as ButtonAnt } from 'antd'
import { MenuFoldOutlined } from '@ant-design/icons'
import ReplySvg from '../svgs/reply'
import { SignJWT } from 'jose'
import axios from '../common/axios'

const NAV = [
  {
    Icon: HomeSvg,
    label: 'Início',
    route: ({ cursoId }) => `/curso/${cursoId}/home`,
    regExp: /home/,
  },
  {
    Icon: LivrosSvg,
    label: 'Módulos',
    route: ({ cursoId }) => `/curso/${cursoId}/modulos`,
    regExp: /modulos/,
  },
  {
    Icon: UsuariosSvg,
    label: 'Tutoria',
    route: ({ cursoId }) => `/curso/${cursoId}/tutoria`,
    regExp: /tutoria/,
  },
  {
    Icon: ForumQuestaoSvg,
    label: 'Fóruns',
    route: ({ cursoId }) => `/curso/${cursoId}/foruns`,
    regExp: /foruns/,
  },
  {
    Icon: ProgressoSvg,
    label: 'Progresso',
    route: ({ cursoId }) => `/curso/${cursoId}/progresso`,
    regExp: /progresso/,
  },
  {
    Icon: AnotacoesSvg,
    label: 'Bloco de Notas',
    route: ({ cursoId }) => `/curso/${cursoId}/bloco-notas`,
    regExp: /bloco-notas/,
  },
  {
    Icon: LivroSvg,
    label: 'Biblioteca',
    route: ({ cursoId }) => `/curso/${cursoId}/biblioteca`,
    regExp: /biblioteca/,
  },
]

interface LayoutParams {
  cursoId: string
}

const RotasLayout = () => {
  const { Layout } = RotasLayout
  return (
    <Layout>
      <Switch>
        <Route exact path="/curso/:cursoId/" component={HomeWrapper} />
        <Route path="/curso/:cursoId/home" component={HomeWrapper} />
        <Route path="/curso/:cursoId/modulos" component={Modulos} />
        <Route path="/curso/:cursoId/tutoria" component={Tutoria} />
        <Route path="/curso/:cursoId/foruns" component={Foruns} />
        <Route exact path="/curso/:cursoId/validacao-facial" component={ValidacaoFacialSerpro} />
        <Route
          exact
          path="/curso/:cursoId/validacao-facial/final"
          component={ValidacaoFacialSerpro}
        />

        <Route path="/curso/:cursoId/progresso" component={Progresso} />
        <Route path="/curso/:cursoId/gabarito/:moduloId/:tipo?" component={QuestoesRespondidas} />

        <Route path="/curso/:cursoId/usuario" component={Usuario} />
        <Route path="/curso/:cursoId/perfil" component={Perfil} />
        <Route path="/curso/:cursoId/muda-senha" component={TrocarSenha} />

        <Route path="/curso/:cursoId/biblioteca" component={Biblioteca} />
        <Route exact path="/curso/:cursoId/bloco-notas" component={BlocoNotas} />
      </Switch>
    </Layout>
  )
}

RotasLayout.Layout = styled('div')`
  max-width: 1072px;
  margin: 24px auto;
  padding: 0 24px;

  @media (max-width: 475px) {
    margin: 12px auto;
  }

  @media (max-width: 768px) {
    margin: 10px auto;
    padding: 0px;
  }
`

const Layout = () => {
  const cursoAtual = useCursoAtual()
  const perfil = useSelector(getUsuarioLogado)
  const { loginZenDesk } = Layout
  const isWide = useMedia({ minWidth: '769px' })

  useEffect(() => {
    loginZenDesk()
  }, [])

  return (
    <IonPage>
      <Switch>
        <Route path="/curso/:cursoId/conteudo/:moduloId" component={Conteudo} />
        <Route path="/curso/:cursoId/modulo-finalizado/:moduloId" component={ModuloFinalizado} />
        <Route path="/curso/:cursoId/regras-avaliacao/:moduloId" component={RegrasAvaliacao} />
        <Route path="/curso/:cursoId/avaliacao/:moduloId" component={Avaliacao} />
        <Route
          path="/curso/:cursoId/resultado-avaliacao/:moduloId"
          component={ResultadoAvaliacao}
        />
        {isWide ? <LayoutDesktop /> : <LayoutMobile />}
      </Switch>
      <Burocracias />
      <BoasVindas />
    </IonPage>
  )
}
Layout.loginZenDesk = async () => {
  if (process.env.NODE_ENV === 'development') return
  const zE = window.zE
  if (!zE) return
  const token = await axios.Usuarios.getTokenZenDesk()
  if (!token) return

  zE(
    'messenger',
    'loginUser',
    function jwtCallback(callback) {
      callback(token)
    },
    function loginCallback(error) {
      if (error) console.error('Zendesk login failed: ', error)
    }
  )
}

const LayoutDesktop = () => {
  return (
    <LayoutDesktopContainer>
      <Topbar className="topbar" />
      <Sidebar className="sidebar" />
      <div className="content">
        <RotasLayout />
      </div>
    </LayoutDesktopContainer>
  )
}

const LayoutMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { Container, Overlay } = LayoutMobile
  return (
    <Container>
      <TopbarMobile className="topbar" openMenu={() => setMenuOpen(!menuOpen)} />
      <SidebarMobile className="sidebar" open={menuOpen} close={() => setMenuOpen(!menuOpen)} />
      {menuOpen && <Overlay />}
      <div className="content">
        <RotasLayout />
      </div>
    </Container>
  )
}

const BoasVindas = () => {
  const { tutoriais } = GlobalStore.useState()
  const dispatch = GlobalStore.useDispatch()
  const { Container } = BoasVindas

  function handleStart() {
    dispatch(GlobalStore.actions.startTutorial('telaInicial'))
  }
  function handleEnd() {
    dispatch(GlobalStore.actions.endTutorial('telaInicial'))
  }

  if (tutoriais.telaInicial === 'FINISHED') return null

  if (tutoriais.telaInicial === 'NOT_STARTED') {
    return (
      <ModalIniciarTutorial
        titulo="Boas vindas a New Driver"
        onStart={handleStart}
        onEnd={handleEnd}
      >
        <Container>
          Que tal começar com um tutorial pelas principais funcionalidades que vão te ajudar a
          estudar?
        </Container>
      </ModalIniciarTutorial>
    )
  }

  return <Tutorial />
}
BoasVindas.Container = styled.div`
  margin: 24px auto;
  max-width: 300px;
`

const Burocracias = () => {
  const { termoUso, politicaPriv } = GlobalStore.useState()

  if (termoUso === 'VISIBLE') return <TermosUso />

  if (politicaPriv === 'VISIBLE') return <PoliticaPriv />

  return null
}

const HomeWrapper = () => {
  const isWide = useMedia({ minWidth: '769px' })
  return <NewHomeOverload width={isWide ? '1024px' : '100%'} className="" />
}

const NewHomeOverload = styled(NewHome)<{ width }>(
  (props) => `
  width: ${props.width};
  margin: 10px auto 30px auto;
`
)

LayoutMobile.Overlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  opacity: 0.6;
  background-color: #000;
`

const SidebarMobile = (props) => {
  const perfil = useSelector(getUsuarioLogado)
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const { Container, Overlay, Usuario, Layout, Menu, MenuContainer, Modal, Sair } = SidebarMobile
  const [logoutConfirmationModal, setLogoutConfirmationModal] = useState(false)
  const history = useHistory()

  const animateProps: any = useSpring({
    transform: props.open ? 'translate3d(0px,0,0)' : 'translate3d(450px,0,0)',
  })

  const logout = () => {
    props.close()
    actions.logout()
    logoutZenDesk()
    location.href = '/'
    setLogoutConfirmationModal(true)
  }

  return (
    <Container id="Container" style={{ ...animateProps, width: props.open ? '100%' : '0%' }}>
      <Modal
        onDidDismiss={() => setLogoutConfirmationModal(false)}
        isOpen={logoutConfirmationModal}
      >
        <div className="content">
          <p className="title">Tem certeza que dejesa sair da sua conta?</p>
          <div className="buttons">
            <Button className="button" onClick={() => logout()} label="Deslogar" />
            <Button
              className="button"
              onClick={() => setLogoutConfirmationModal(false)}
              label="Cancelar"
            />
          </div>
        </div>
      </Modal>
      <Layout>
        <Overlay onClick={props.close} />
        <Menu id="Menu">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Usuario perfil={perfil} props={props} />

            <ButtonAnt
              icon={<MenuFoldOutlined />}
              style={{ height: '100%' }}
              onClick={() => {
                props.close()
              }}
            />
          </div>

          <MenuContainer>
            <MenuItem
              timeout
              item={{
                Icon: ReplySvg,
                label: 'Trocar de curso',
                route: () => `/seleciona-curso`,
                regExp: /trocar-curso/,
              }}
              close={props.close}
              key={'Trocar de curso'}
            />
            {NAV.map((item, i) => (
              <MenuItem timeout item={item} close={props.close} key={item.label} />
            ))}
          </MenuContainer>
          <Sair onClick={logout}>Sair do Aplicativo</Sair>
        </Menu>
      </Layout>
    </Container>
  )
}

SidebarMobile.Sair = styled('div')`
  background-color: white;
  color: #f06f30;
  padding: 18px;
  border-top: 1px solid #c4c4c4;
`

SidebarMobile.MenuContainer = styled('div')``

SidebarMobile.Modal = styled(IonModal)`
  --height: 165px;
  --width: 90%;
  --max-width: 400px;
  --border-radius: 8px;
  .content {
    .title {
      text-align: center;
      padding-bottom: 20px;
    }
    padding: 15px;
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      .button {
        width: 45%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

SidebarMobile.Container = styled(animated.div)`
  z-index: 5;
  display: block;
  position: absolute;
  height: 100%;
  right: 0px;
`

SidebarMobile.Usuario = ({ perfil, props }) => {
  const history = useHistory()
  const { cursoId } = useParams<LayoutParams>()

  const abrePerfil = () => {
    props.close()
    history.push(`/curso/${cursoId}/perfil`)
  }

  return (
    <UsuarioContainer onClick={abrePerfil}>
      <Avatar tamanho={30} circular usuario={perfil} />
      <UsuarioContainerNome> {perfil.nome} </UsuarioContainerNome>
      <Show condition={isPlatform('ios')}>
        <UsuarioCloseMenu onClick={props.close}>
          <IonIcon icon={closeIcon} />
        </UsuarioCloseMenu>
      </Show>
    </UsuarioContainer>
  )
}

const UsuarioCloseMenu = styled('div')`
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 2em;
`

const UsuarioContainer = styled('div')`
  display: grid;
  grid-template-columns: 35px auto;
  padding: 21px 18px;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
`

const UsuarioContainerNome = styled('div')`
  font-weight: 700;
  color: #000;
  align-self: center;
  justify-self: start;
`

SidebarMobile.Menu = styled('div')`
  display: grid;
  grid-template-rows: 73px auto 61px;
  background-color: white;
  overflow-y: scroll;
  padding-bottom: 60px;
  width: 100%;
`

SidebarMobile.Overlay = styled('div')`
  opacity: 0.1;
`

SidebarMobile.Layout = styled('div')`
  height: 100%;
  display: grid;
  grid-template-columns: ${!isPlatform('ios') ? 'auto 253px' : '0 100%'};
`

const Topbar = ({ className }) => {
  const { cursoId } = useParams<LayoutParams>()
  const perfil = useSelector(getUsuarioLogado)
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const history = useHistory()
  const cursoAtual = useCursoAtual()
  const { openZenDeskChat: openJivoChat } = useOpenZenDeskChat()
  const [contextMenuVisible, setContextMenuVisible] = useState(false)
  const globalDispatch = GlobalStore.useDispatch()

  const abrePerfil = () => {
    setContextMenuVisible(false)
    history.push(`/curso/${cursoId}/perfil`)
  }

  const logout = () => {
    setContextMenuVisible(false)
    actions.logout()
    logoutZenDesk()
    location.href = '/'
  }

  const trocarDeCurso = () => {
    setContextMenuVisible(false)
    history.push('/seleciona-curso')
  }

  const handleClickLogo = () => {
    history.push(`/curso/${cursoId}/home`)
  }

  const suporte = () => {
    openJivoChat({
      customData: [{ title: 'Local', content: 'Suporte Geral' }],
    })
  }

  const tutorial = () => {
    globalDispatch(GlobalStore.actions.startTutorial('telaInicial'))
  }

  const termoUso = () => {
    globalDispatch(GlobalStore.actions.showTermoUso())
  }

  const politicaPriv = () => {
    globalDispatch(GlobalStore.actions.showPoliticaPriv())
  }

  return (
    <TopbarContainer className={className}>
      <img className="logo" src={LogoNewDriver} onClick={handleClickLogo} />
      <div className="titulo">{cursoAtual?.nome}</div>
      <div onClick={() => setContextMenuVisible(!contextMenuVisible)} className="avatar">
        <Avatar tamanho={40} circular usuario={perfil} />
        <AvatarNome>{perfil.nome}</AvatarNome>
        <ContextMenu
          visible={contextMenuVisible}
          position="bottomRight"
          onClose={() => setContextMenuVisible(!contextMenuVisible)}
        >
          <ContextMenuItem onClick={trocarDeCurso} label="Trocar de curso" />
          <ContextMenuItem onClick={abrePerfil} label="Perfil" />
          <ContextMenuItem onClick={suporte} label="Suporte" />
          <ContextMenuItem onClick={tutorial} label="Tutorial" />
          <ContextMenuItem onClick={termoUso} label="Termo de Uso" />
          <ContextMenuItem onClick={politicaPriv} label="Política de Privacidade" />
          <ContextMenuItem onClick={logout} label="Sair" />
        </ContextMenu>
      </div>
    </TopbarContainer>
  )
}

const TopbarMobile = ({ className, openMenu }) => {
  const { Container } = TopbarMobile
  const cursoAtual = useCursoAtual()
  return (
    <Container className={className}>
      <div />
      <div className="titulo"> {cursoAtual?.nome} </div>
      <div className="menu" onClick={openMenu}>
        <IonIcon icon={menuHamburguer} />
      </div>
    </Container>
  )
}

TopbarMobile.Container = styled('div')`
  display: grid;
  grid-template-columns: 54px auto 54px;
  place-items: center;
  .titulo {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
  .menu {
    font-size: 2em;
    height: 32px;
  }
`

const Avatar = styled(AppAvatar)``

const AvatarNome = styled('span')`
  display: inline-block;
  align-self: center;
  justify-self: center;
  font-size: 16px;
  color: #f2f2f2;
  margin-left: 10px;
`

const TopbarContainer = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr 200px;
  .logo {
    width: 121px;
    height: 44px;
    margin-left: 42px;
    align-self: center;
    justify-self: center;
    cursor: pointer;
  }
  .titulo {
    font-size: 18px;
    align-self: center;
    justify-self: center;
  }
  .avatar {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    align-self: center;
    justify-self: start;
  }
`

const Sidebar = ({ className }) => {
  return (
    <div className={className}>
      {NAV.map((item, i) => (
        <MenuItem key={item.label} item={item} />
      ))}
    </div>
  )
}

const MenuItem = ({ item, close = () => null, timeout = false }) => {
  const history = useHistory()
  const { cursoId } = useParams<LayoutParams>()
  const location = useLocation()
  const active = item.regExp.test(location.pathname)

  const toRoute = () => setTimeout(() => history.push(item.route({ cursoId })), timeout ? 500 : 0)

  const click = () => {
    close()
    toRoute()
  }

  const { Container, Item } = MenuItem
  const Icone = item.Icon

  return (
    <Container active={active} onClick={click}>
      <Item active={active}>
        <div className="link">
          <Icone color={active ? 'white' : '#FF9933'} />
          <span className="label">{item.label}</span>
        </div>
      </Item>
    </Container>
  )
}

MenuItem.Container = styled('div')<{ active }>(
  (props) => `
  padding: 5px;
  ${props.active ? 'background-color: #F06F30;' : ''}
`
)

MenuItem.Item = styled('div')<{ active }>(
  (props) => `
  cursor: pointer;
  padding: 18px 20px;
  .link {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .label {
    font-size: 17px;
    color: ${props.active ? 'white' : 'black'};
    ${props.active ? 'font-weight: 700;' : ''}
    margin-left: 10px;
  }
`
)

LayoutMobile.Container = styled('div')`
  display: grid;
  height: 100%;
  grid-template-rows: 60px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'topbar'
    'content';
  .topbar {
    grid-area: topbar;
    background-color: #ff9722;
    color: #fff;
    width: 100%;
    text-align: center;
  }
  .content {
    grid-area: content;
    overflow-y: auto;
  }
`

const LayoutDesktopContainer = styled('div')`
  display: grid;
  height: 100%;
  grid-template-rows: 77px 1fr;
  grid-template-columns: 204px 1fr;
  grid-template-areas:
    'topbar topbar'
    'sidebar content';
  .topbar {
    grid-area: topbar;
    background-color: #ff9722;
    color: #fff;
    width: 100%;
    text-align: center;
  }
  .sidebar {
    padding-top: 8px;
    grid-area: sidebar;
    background-color: #f7f7f7;
  }
  .content {
    grid-area: content;
    overflow-y: auto;
  }
`

export default Layout
