export interface CustomDataType {
  /**
   * Content of data field. Tags will be insulated
   */
  content?: string
  /**
   * Title shown above a data field
   */
  title?: string
  /**
   * URL that opens when you click on a data field
   */
  link?: string
  /**
   * Description of the data field, bold text before a colon
   */
  key?: string
}

export const useOpenZenDeskChat = () => {
  const openZenDeskChat = ({ customData }: { customData?: CustomDataType[] }) => {
    const zE = window.zE
    if (!zE) return

    zE('messenger', 'show')
    zE('messenger', 'open')
  }

  return { openZenDeskChat }
}

export const logoutZenDesk = () => {
  try {
    window.zE('messenger', 'logoutUser')
  } catch (error) {
    console.error('Erro ao tentar deslogar do ZenDesk', error)
  }
}
