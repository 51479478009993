import React from 'react'
import styled from '@emotion/styled'
import Svgs from '../svgs'
import { Button, TituloPagina } from '../components'
import { useOpenZenDeskChat } from '../common/hooks/useZenDeskApi'
import { useSelector } from 'react-redux'
import { getUsuarioLogado } from '../auth/AuthStore'
import useAxios from 'axios-hooks'
import { useParams } from 'react-router'

export interface ConteudoParamsType {
  cursoId
}

const Tutoria = () => {
  const { Layout, Telefones, TituloContato, Email, Contato, Label, Botao, ContainerContatos } =
    Tutoria

  const { cursoId } = useParams<ConteudoParamsType>()

  const { openZenDeskChat: openJivoChat } = useOpenZenDeskChat()
  const perfil = useSelector(getUsuarioLogado)

  const [{ data: matricula }] = useAxios(`/matriculas/curso/${cursoId}/self`)

  function handleClickFalarTutor() {
    openJivoChat({ customData: [{ title: 'Local', content: 'Tela de Tutoria' }] })
  }

  return (
    <Layout>
      <TituloPagina>Tutoria</TituloPagina>
      <Label style={{ marginBottom: '18px' }}>
        Tire suas dúvidas falando com nossa equipe de tutores via chat e em até 24 horas te
        responderemos
      </Label>

      <Botao
        label="Falar com tutor"
        svgPosition="LEFT"
        svg={<Svgs.Users color="white" />}
        onClick={handleClickFalarTutor}
      />

      <Contato>
        <TituloPagina>Contatos</TituloPagina>
        <Label>Se preferir, entre em contato diretamente pelo telefone ou por e-mail</Label>
        <ContainerContatos>
          <Telefones className="telefones">
            <TituloContato>
              <Svgs.Phone style={{ marginRight: 8 }} /> Telefones:
            </TituloContato>
            <div style={{ display: 'flex' }}>
              <span style={{ alignSelf: 'center' }}>(62) 3086-7360</span>
              <a
                href={`https://wa.me/556230867360?text=Olá, tudo bem? Sou o ${perfil.nome}, CPF ${perfil.cpf}, estado ${matricula?.uf} e gostaria que tirasse algumas dúvidas`}
              >
                <Botao
                  style={{ background: '#00A884' }}
                  label="Conversar via Whatsapp"
                  svgPosition="LEFT"
                  svg={<Svgs.WhatsApp color="white" />}
                />
              </a>
            </div>
          </Telefones>
          <Email className="email">
            <TituloContato>
              <Svgs.Mail style={{ marginRight: 8 }} /> E-mail:
            </TituloContato>
            <div> tutoria@newdriver.com.br </div>
          </Email>
        </ContainerContatos>
      </Contato>
    </Layout>
  )
}
Tutoria.Layout = styled.div`
  @media (max-width: 769px) {
    padding: 24px;
  }
  @media (max-width: 450px) {
    padding: 12px;
  }
  color: #2d2d2d;
  font-size: 14px;
`
Tutoria.Contato = styled.div`
  border-top: 1px solid #d9d9d9;
  margin-top: 29px;
  padding-top: 29px;
`
Tutoria.ContainerContatos = styled.div`
  display: grid;
  gap: 48px;
  grid-template-columns: auto 1fr;
  margin-top: 18px;
  margin-left: 16px;
  @media (max-width: 450px) {
    grid-template-columns: auto;
    gap: 18px;
  }
`
Tutoria.Telefones = styled.div`
  display: grid;
  gap: 18px;
  align-content: start;

  a {
    text-decoration: none;
  }
`
Tutoria.Email = styled.div`
  display: grid;
  gap: 18px;
  align-content: start;
`
Tutoria.TituloContato = styled.div`
  display: flex;
  font-weight: bold;
  letter-spacing: 0.2px;
`
Tutoria.Botao = styled(Button)`
  margin-left: 16px;
`
Tutoria.Label = styled.div`
  margin-top: 18px;
  margin-left: 16px;
`

export { Tutoria }
