import React, { useState } from 'react'
import { getUsuarioLogado } from '../auth/AuthStore'
import { useSelector } from 'react-redux'
import { ConteudoStore as Store, ConteudoSelectors } from './ConteudoStore'
import { useParams, useHistory } from 'react-router'
import styled from '@emotion/styled'
import { animated, useSpring } from 'react-spring'
import { ellipsisVertical } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { ContextMenuItem, ContextMenu } from '../common/ContextMenu'
import Svgs from '../svgs'
import { ConteudoParamsType } from './Conteudo'
import { useOpenZenDeskChat } from '../common/hooks/useZenDeskApi'
import { useWindowSize } from '../common/hooks/useWindowSize'

const AcoesPagina = () => {
  const { width } = useWindowSize()
  const usuario = useSelector(getUsuarioLogado)
  const dispatch = Store.useDispatch()
  const { cursoId } = useParams<ConteudoParamsType>()
  const history = useHistory()
  const { moduloAtual } = Store.useState()
  const { openZenDeskChat: openJivoChat } = useOpenZenDeskChat()
  const acoes = {
    aumentaFonte() {
      dispatch(Store.actions.aumentaFonte())
    },
    diminuiFonte() {
      dispatch(Store.actions.diminuiFonte())
    },
    abreChatTutor() {
      dispatch(Store.actions.openChatTutor())
      openJivoChat({
        customData: [
          { title: 'Local', content: 'Conteúdo' },
          { title: 'Módulo', content: moduloAtual?.nome },
        ],
      })
    },
    abreBlocoNotas() {
      dispatch(Store.actions.openPainelNota())
    },
    sairConteudo() {
      dispatch(Store.actions.sairConteudo())
      history.replace(`/curso/${cursoId}/modulos`)
    },
  }

  const WIDTH_DESKTOP = 845
  return (width ?? 0) > WIDTH_DESKTOP ? (
    <AcoesPaginaDesktop acoes={acoes} />
  ) : (
    <AcoesPaginaMobile acoes={acoes} />
  )
}
AcoesPagina.Container = styled(animated.div)`
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: absolute;
  transform: translate(0px, 0px);
  z-index: 3;
  background: white;
  width: 100%;
`
AcoesPagina.Botao = styled('div')`
  border-radius: 4px;
  height: 32px;
  transition: background 0.25s;
  font-size: 0.9em;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    background: #f2f2f2;
  }
`
const AcoesPaginaDesktop = ({ acoes }) => {
  const { aumentaFonte, diminuiFonte, abreChatTutor, abreBlocoNotas, sairConteudo } = acoes
  const { Container, BotaoAlteraTamanhoFonte, BotaoAcao } = AcoesPaginaDesktop
  return (
    <Container>
      <BotaoAlteraTamanhoFonte onClick={diminuiFonte}>
        <Svgs.DecreaseFontSize />
      </BotaoAlteraTamanhoFonte>
      <BotaoAlteraTamanhoFonte onClick={aumentaFonte}>
        <Svgs.IncreaseFontSize />
      </BotaoAlteraTamanhoFonte>
      <BotaoAcao onClick={abreChatTutor}>
        <Svgs.Users height={16} style={{ marginRight: 4 }} />
        Falar com tutor
      </BotaoAcao>
      <BotaoAcao onClick={abreBlocoNotas}>
        <Svgs.Notes height={16} style={{ marginRight: 4 }} />
        Bloco de notas
      </BotaoAcao>
      <BotaoAcao onClick={sairConteudo}>
        <Svgs.Exit height={16} style={{ marginRight: 4 }} />
        Sair do conteúdo
      </BotaoAcao>
    </Container>
  )
}
AcoesPaginaDesktop.Container = styled(AcoesPagina.Container)`
  top: 56px;
  height: 58px;
`
AcoesPaginaDesktop.BotaoAlteraTamanhoFonte = styled(AcoesPagina.Botao)`
  width: 32px;
  border: 1px solid #c4c4c4;
  color: var(--ion-color-primary);
  font-weight: bold;
`
AcoesPaginaDesktop.BotaoAcao = styled(AcoesPagina.Botao)`
  padding: 0 12px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AcoesPaginaMobile = ({ acoes }) => {
  const { aumentaFonte, diminuiFonte, sairConteudo } = acoes
  const state = Store.useState()
  const { isTopbarActionsVisible } = state
  const paginaAtual = ConteudoSelectors.getNumeroPaginaAtual(state)
  const { Container, Pagina, BotaoOpcoes, BotaoMenu } = AcoesPaginaMobile
  const [contextVisible, setContextVisible] = useState(false)
  const props = useSpring({
    transform: isTopbarActionsVisible ? 'translate(0px, 0px)' : 'translate(0px, -60px)',
  })

  function abreChatTutor() {
    setContextVisible(false)
    acoes.abreChatTutor()
  }
  function abreBlocoNotas() {
    setContextVisible(false)
    acoes.abreBlocoNotas()
  }

  return (
    <Container style={props}>
      <Pagina> Página {paginaAtual} </Pagina>
      <div style={{ position: 'relative' }}>
        <BotaoOpcoes onClick={() => setContextVisible(true)}>
          Opções
          <IonIcon icon={ellipsisVertical} />
        </BotaoOpcoes>
        <ContextMenu
          visible={contextVisible}
          position="bottomLeft"
          onClose={() => setContextVisible(false)}
        >
          <ContextMenuItem
            onClick={abreChatTutor}
            label="Falar com tutor"
            icon={<Svgs.Users height={16} />}
          />
          <ContextMenuItem
            onClick={abreBlocoNotas}
            label="Bloco de notas"
            icon={<Svgs.Notes height={16} />}
          />
          <ContextMenuItem
            onClick={diminuiFonte}
            label="Diminuir Fonte"
            icon={<Svgs.DecreaseFontSize height={16} />}
          />
          <ContextMenuItem
            onClick={aumentaFonte}
            label="Aumentar Fonte"
            icon={<Svgs.IncreaseFontSize height={16} />}
          />
          <ContextMenuItem
            onClick={sairConteudo}
            label="Sair do conteúdo"
            icon={<Svgs.Exit height={16} />}
          />
        </ContextMenu>
      </div>
    </Container>
  )
}
AcoesPaginaMobile.Container = styled(AcoesPagina.Container)`
  top: 56px;
  height: 41px;
  justify-content: space-between;
  padding: 0 16px;
`
AcoesPaginaMobile.Pagina = styled('div')`
  font-size: 10px;
`
AcoesPaginaMobile.BotaoOpcoes = styled(AcoesPagina.Botao)`
  grid-template-columns: auto auto;
  padding: 0 12px;
  height: 32px;
`
AcoesPaginaMobile.BotaoMenu = styled(AcoesPagina.Botao)`
  width: 186px;
  height: 42px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  place-items: center;
  margin: 0;
  justify-items: start;
  border-radius: 0;
  padding-left: 8px;
`

export { AcoesPagina }
