import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '../auth/AuthStore'
import clock from '../assets/icons/clock.svg'
import add_to_queue from '../assets/icons/add_to_queue.svg'
import exclamation from '../assets/icons/exclamation-circle.svg'
import userAvatar from '../assets/icons/carbon_user-avatar-filled.svg'
import { format } from 'date-fns'
import { cursosActions } from './CursosStore'
import { IStateRedux } from '../common/ReduxStore'
import { CheckOutlined, BookOutlined, UserOutlined } from '@ant-design/icons'
import { STATUS_MATRICULA, TSTATUS_MATRICULA } from '../models/MatriculaModel'
import logoPadrao from '../assets/marca/aplicacao_padrao.png'
import { Button } from '../components'
import { Show } from '../common/Show'
import { logoutZenDesk } from '../common/hooks/useZenDeskApi'

export function SelecionaCurso() {
  const { Layout } = SelecionaCurso
  useEffect(() => {
    logoutZenDesk()
  }, [])
  return (
    <Layout id="Layout">
      <MenuAppBar />
      <ListaCursosAluno />
    </Layout>
  )
}
SelecionaCurso.Layout = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
`

function MenuAppBar() {
  const { Layout } = MenuAppBar
  return (
    <Layout>
      <img height="50vw" src={logoPadrao} />
      <Menu />
    </Layout>
  )
}
MenuAppBar.Layout = styled.div`
  background-color: #ffff;
  height: 10vh;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const history = useHistory()
  const { MenuContainer, MenuOptions } = Menu
  const menuRef = useRef<any>(null)

  function handleLogout() {
    setIsMenuOpen(false)
    actions.logout()
    logoutZenDesk()
    location.href = '/'
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [menuRef])

  return (
    <MenuContainer ref={menuRef}>
      <img
        src={userAvatar}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        style={{
          cursor: 'pointer',
        }}
      />
      {isMenuOpen ? (
        <MenuOptions>
          {/* <li>Meus Cursos</li> */}
          {/* <li>Configurações</li> */}
          <li onClick={handleLogout}>Sair</li>
        </MenuOptions>
      ) : null}
    </MenuContainer>
  )
}
Menu.MenuContainer = styled.div`
  position: relative;
`
Menu.MenuOptions = styled.ul`
  background-color: #f1f1f1;
  border-radius: 4px;
  list-style: none;
  margin: 0;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 3rem;

  li {
    cursor: pointer;
    padding: 0.5rem;
  }

  li:hover {
    background-color: #ddd;
  }
`

function ListaCursosAluno() {
  const dispatch = useDispatch()
  const cursosAluno = useSelector((s: IStateRedux) => s.cursos.cursosUsuario)
  const { message, fetching } = useSelector((s: IStateRedux) => s.cursos.request)
  const { CursosWrap, Hr, Strong, TitleContainer } = ListaCursosAluno

  useEffect(() => {
    dispatch(cursosActions.getCursosAluno())
  }, [])

  const cursosAtivos = useMemo(
    () =>
      cursosAluno.filter((f) => ['MATRICULADO', 'AGUARDANDO_PAGAMENTO'].includes(f.statusDetran)),
    [cursosAluno]
  )

  const outrosCursosFinalizados = useMemo(() => {
    return cursosAluno.filter(
      (f) => !['FINALIZADO', 'MATRICULADO', 'AGUARDANDO_PAGAMENTO'].includes(f.statusDetran)
    )
  }, [cursosAluno])

  const cursosFinalizados = useMemo(
    () => cursosAluno.filter((f) => f.statusDetran === 'FINALIZADO'),
    [cursosAluno]
  )

  if (fetching) return <>Carregando ... </>
  if (message) return <>Ocorreu um erro ao buscar os cursos</>

  return (
    <CursosWrap id="MenuCursoWrap">
      <Show condition={!!cursosAtivos.length}>
        <TitleContainer>
          <Strong>Meus Cursos Ativos</Strong>
          <Hr />
        </TitleContainer>
        {cursosAtivos.map((curso, key) => (
          <CursosButton key={key} curso={curso} />
        ))}
      </Show>

      <Show condition={!!outrosCursosFinalizados.length}>
        <TitleContainer>
          <Strong>Outros Status de Cursos</Strong>
          <Hr />
        </TitleContainer>
        {outrosCursosFinalizados.map((curso, key) => (
          <CursosButton key={key} curso={curso} />
        ))}
      </Show>

      <Show condition={!!cursosFinalizados.length}>
        <TitleContainer>
          <Strong>Meus Cursos Finalizados</Strong>
          <Hr />
        </TitleContainer>
        {cursosFinalizados.map((curso, key) => (
          <CursosButton key={key} curso={curso} />
        ))}
      </Show>
    </CursosWrap>
  )
}
ListaCursosAluno.Hr = styled.hr`
  border-top: 1px solid;
  width: 100%;
  border-color: #d9d9d9;
`
ListaCursosAluno.Strong = styled.strong`
  width: 100%;
  margin-top: 30px;
  color: #2d2d2d;
`
ListaCursosAluno.CursosWrap = styled.div`
  height: 85vh;
  width: 100%;
  overflow-y: scroll;
  background: #eee9e9;

  padding: 0px 24px 24px 24px;
  margin: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
ListaCursosAluno.TitleContainer = styled.div`
  max-width: 976px;
  width: 100%;
  margin: 0 auto;
  color: rgb(25, 25, 25);
  margin-top: 24px;
`

const CursosButton = ({ curso }) => {
  const { Container, Img, Duracao, Curso, Status, ButtonAcessar } = CursosButton
  const history = useHistory()
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)

  const handleCursos = async (curso) => {
    actions.newTokenMatricula({
      matriculaId: curso.matriculaId,
      callback: () => history.replace(`/curso/${curso.id}/home`),
    })
  }

  const urlImg = process.env.PUBLIC_URL + '/assets/cursos/' + curso.id + '.webp'
  return (
    <Container id="MenuCurso" key={curso.id} onClick={() => handleCursos(curso)}>
      <Img src={urlImg} />

      <Curso>
        <div>{curso?.categorias}</div>
        <strong>{curso?.nome}</strong>
      </Curso>

      <Duracao>
        <img src={clock} />
        <span>{curso.cargaHoraria}h</span>

        <img src={add_to_queue} />
        <span>Aulas 100% online</span>
      </Duracao>

      <Status>
        <div>
          {STATUS_DETRAN[curso.statusDetran] ?? ''}
          {String(curso.statusDetran).replace(/_/g, ' ')}
        </div>

        {curso.dataFimCurso && (
          <strong style={{ fontSize: 12 }}>
            Data de Conclusão: {format(new Date(curso.dataFimCurso), 'dd/MM/yyyy HH:mm')}
          </strong>
        )}
      </Status>

      <ButtonAcessar>
        {curso.statusDetran === STATUS_MATRICULA.FINALIZADO ? (
          <Button color={'#3155A4'} label="Revisar" />
        ) : (
          <Button label="Acessar" />
        )}
      </ButtonAcessar>
    </Container>
  )
}
CursosButton.Container = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 256px 1fr 150px;
  grid-template-rows: 75px 75px;
  grid-template-areas:
    'Imagem Curso Botao'
    'Imagem Duracao Status';
  max-width: calc(1024px - 48px);
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 6px;

  @media (max-width: 756px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'Imagem'
      'Curso'
      'Duracao'
      'Status'
      'Botao';
  }
`
CursosButton.Img = styled.img`
  grid-area: Imagem;
  width: 100%;
  object-fit: cover;
  height: 150px;
`
CursosButton.Curso = styled.div`
  grid-area: Curso;
  margin-top: 20px;
  margin-left: 15px;
  div {
    font-size: 14px;
  }
  strong {
    font-size: 16px;
    margin-top: 6px;
    display: block;
  }

  @media (max-width: 756px) {
    margin-right: 10px;
  }
`
CursosButton.Duracao = styled.div`
  grid-area: Duracao;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;

  @media (max-width: 756px) {
    margin-top: 10px;
  }

  img {
    margin: 5px;
  }

  span {
    margin-right: 5px;
    margin-top: 1px;
  }
`
CursosButton.Status = styled.div`
  grid-area: Status;
  font-size: 12px;
  color: #6a737c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  strong {
    text-align: center;
  }
`
CursosButton.ButtonAcessar = styled.div`
  grid-area: Botao;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 756px) {
    flex: 1;
    margin-top: 5px;
  }

  button {
    width: 128px;
    height: 37px;
    @media (max-width: 756px) {
      flex: 1;
    }
  }
`

const STATUS_DETRAN: Record<TSTATUS_MATRICULA, any> = {
  MATRICULADO: <BookOutlined style={{ fontSize: 16, color: '#FB7800' }} />,
  FINALIZADO: <CheckOutlined style={{ fontSize: 16, color: '#FB7800' }} />,
  AGUARDANDO_ENVIO: <img src={exclamation} />,
  AGUARDANDO_PAGAMENTO: <img src={exclamation} />,
  AGUARDANDO_CONCLUSAO_MODULO_PRATICO: <img src={clock} />,
  AGUARDANDO_MATRICULA_MODULO_PRATICO: <img src={clock} />,
  AGUARDANDO_MATRICULA: <img src={clock} />,
}
