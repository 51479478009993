import React from 'react'
import { IonLabel, IonItem, IonContent, IonPage, IonButton } from '@ionic/react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../auth/AuthStore'
import { IStateRedux } from '../common/ReduxStore'
import { Redirect, useParams, useHistory } from 'react-router'
import Header from '../components/Header'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { cursosActions } from '../cursos/CursosStore'
import { logoutZenDesk } from '../common/hooks/useZenDeskApi'

interface UsuarioParams {
  cursoId: string
}

export const Usuario = () => {
  const dispatch = useDispatch()
  const actionsAuth = bindActionCreators(authActions, dispatch)
  const token = useSelector((s: IStateRedux) => s.auth.token)
  const { cursoId } = useParams<UsuarioParams>()
  const history = useHistory()

  function handleClickSair() {
    console.log('sair')
    dispatch(actionsAuth.logout())
    logoutZenDesk()
    location.href = '/'
  }

  if (!token) return <Redirect to="" />

  return (
    <div>
      <Styles.IonContent className="padding">
        <div className="container">
          <h2>Selecione uma opção</h2>
          <IonItem button href={`curso/${cursoId}/perfil`}>
            <IonLabel>Perfil</IonLabel>
          </IonItem>
          <IonItem button href={`curso/${cursoId}/muda-senha`}>
            <IonLabel>Trocar a senha</IonLabel>
          </IonItem>
          <IonItem button href="/seleciona-curso">
            <IonLabel>Selecionar outro curso</IonLabel>
          </IonItem>
          <IonItem button onClick={handleClickSair}>
            <IonLabel>Sair</IonLabel>
          </IonItem>
          <Styles.ButtonVoltarContainer>
            <IonButton expand="block" onClick={() => history.goBack()}>
              Voltar para a tela inicial
            </IonButton>
          </Styles.ButtonVoltarContainer>
        </div>
      </Styles.IonContent>
    </div>
  )
}

const Styles = {
  IonContent: styled('div')`
    --background: #f7f7f7;
    .container {
      background: white;
      padding: 24px;
      max-width: 800px;
      @media (min-width: 800px) {
        margin: 12px auto;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      }
    }
  `,
  ButtonVoltarContainer: styled('div')`
    margin-top: 32px;
  `,
}
