// import CommonStore from './stores/CommonStore'
import { useContext } from 'react'
import axios from 'axios'

import { ChatMensagemModel } from '../models/ChatMensagemModel'
import { ChatModel } from '../models/ChatModel'
import { ConteudoPaginaModel } from '../models/ConteudoPaginaModel'
import { ForumMensagemModel } from '../models/ForumMensagemModel'
import { ForumModel } from '../models/ForumModel'
import { ProgressoModel } from '../models/ProgressoModel'
import { QuestaoModel } from '../models/QuestaoModel'
import { UsuarioModel } from '../models/UsuarioModel'
import { CursoModel } from '../models/CursoModel'
import { ModuloModel } from '../models/ModuloModel'
import { RespostaAlternativaModel } from '../models/RespostaAlternativaModel'
import { RespostaModel } from '../models/RespostaModel'

const apiUrl = process.env.REACT_APP_API_URL // 'http://localhost:3500/api'
axios.defaults.baseURL = process.env.REACT_APP_API_URL // 'http://localhost:3500/api'
axios.defaults.headers.common = { ...axios.defaults.headers.common, platform: 'APP' }
export interface IQueryParams {
  order?: 'ASC' | 'DESC'
  orderBy?: string
  orderByEntity?: string
  offset?: number
  limit?: number
}

export interface ICounter<T> {
  count: number
  rows: T[]
}

const handleErrors = (err: any) => {
  if (err && err.response && err.response.status === 401) {
    _setToken('')
    _setError('Você não tem permissão para acessar esse recurso')
    // LoginStore.logout()
    // LoginStore.setError('Você não tem permissão para acessar esse recurso')
  }
  if (err.response && err.response.data && err.response.data.message)
    err.message = err.response.data.message
  // _setIsLoading(false)
  throw err
}

const responseBody = (res: any) => {
  // _setIsLoading(false)
  return res.data
}

const getHeaders = () => {
  const token = localStorage.getItem('token') as string
  if (token) return { Authorization: `Bearer ${token}` }
  return { Authorization: '' }
}
// let _setIsLoading: any
let _setToken: any
let _setError: any
const requests = {
  delete: async (url: string) => {
    // _setIsLoading(true)
    return await axios
      .delete(apiUrl + url, { headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  get: async (url: string, params?: any) => {
    // _setIsLoading(true)
    return await axios
      .get(apiUrl + url, { params, headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  put: async (url: string, body: any) => {
    // _setIsLoading(true)
    return await axios
      .put(apiUrl + url, body, { headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  post: async (url: string, body: any, upload = false) => {
    const headers: any = getHeaders()
    if (upload) headers['Content-Type'] = 'multipart/form-data'
    // _setIsLoading(true)
    return await axios
      .post(apiUrl + url, body, { headers })
      .then(responseBody)
      .catch(handleErrors)
  },
}

const Usuarios = {
  login: async (cpf: string, senha: string): Promise<{ token: string; alterarSenha: boolean }> =>
    await axios.post('/usuarios/login', { cpf, senha }).then((x) => x.data),
  newTokenMatricula: async (matriculaId: string): Promise<{ token: string }> =>
    await requests.post('/usuarios/new-token-matricula', { matriculaId }),
  validateSenha: async (senha: string) =>
    await requests.post('/usuarios/validate-senha', { senha }),
  revalidaToken: async (usuarioId: number): Promise<{ token: string }> =>
    await requests.get(`/usuarios/revalida-token/${usuarioId}`),
  getByConta: async (contaId: number): Promise<UsuarioModel[]> =>
    await requests.get(`/usuarios/conta/${contaId}`),
  createOrUpdate: async (usuario: UsuarioModel): Promise<UsuarioModel> =>
    await requests.post('/usuarios', usuario),
  delete: async (usuarioId: number): Promise<boolean> =>
    await requests.delete(`/usuarios/${usuarioId}`),
  getByCpf: async (cpf: string): Promise<UsuarioModel> =>
    await requests.get(`/usuarios/cpf/${cpf}`),
  updateSenha: async (
    usuarioId: number,
    senha: string,
    { senhaAtual, token }: { senhaAtual?: string; token?: string }
  ): Promise<boolean> =>
    await requests.put('/usuarios/senha', { usuarioId, senha, senhaAtual, token }),
  getByToken: async (token: string): Promise<UsuarioModel> =>
    await requests.get(`/usuarios/token/${token}`),
  createTokenRecuperaSenha: async (email: string): Promise<boolean> =>
    await requests.put('/usuarios/token', { email }),
  deleteFromConta: async (usuarioId: number, contaId: number) =>
    await requests.delete(`/usuarios/${usuarioId}/conta/${contaId}`),
  getAllByPerfil: async (perfil: string, query: IQueryParams): Promise<ICounter<UsuarioModel>> =>
    await requests.get(`/usuarios/perfil/${perfil}`, query),
  recuperarSenha: async (cpf: string): Promise<boolean> =>
    await axios.post('/usuarios/recuperasenha', { cpf }),
  alterarSenhaToken: async (senha: string, token: string): Promise<boolean> =>
    await axios.post('/usuarios/alterasenha/token', { senha, token }),
  alterarSenha: async (
    usuarioId: string,
    senhaAtual: string,
    novaSenha: string
  ): Promise<boolean> =>
    await axios.post('/usuarios/alteraSenha', { usuarioId, novaSenha, senhaAtual }),
  getTokenZenDesk: async () => requests.post('/usuarios/tokenzendesk', {}),
}

const Files = {
  upload: async (formData: any): Promise<{ filepath: string }> =>
    await requests.post('/files', formData, true),
}

const Modulos = {
  get: async ({
    query,
    cursoId,
  }: {
    query?: IQueryParams
    cursoId: number
  }): Promise<ICounter<ModuloModel>> => {
    const params = {
      ...(query || {}),
      ...(cursoId ? { cursoId } : {}),
    }
    return await requests.get('/modulos/table', params)
  },
  getModulosByCursoComprogresso: async (cursoId: number): Promise<ModuloModel[]> =>
    await requests.get(`/modulos/curso/${cursoId}/progresso`),
  post: async (modulo: Partial<ModuloModel>): Promise<ModuloModel[]> =>
    await requests.post('/modulos', modulo),
  delete: async (id: string | number): Promise<ModuloModel[]> =>
    await requests.delete(`/modulos/${id}`),
}

const Avaliacoes = {
  refazerAvaliacao: async (moduloId: number) =>
    await requests.post(`/app/avaliacoes/refazer/${moduloId}`, {}),
}

// const UsuariosCursos = {
//   get: ({ query, cursoId, papel }: { query?: IQueryParams, cursoId: number, papel: string }): Promise<ICounter<Usuario>> => {
//     const params = {
//       ...(query || {}),
//     }
//     return requests.get(`/cursos/${cursoId}/papel/${papel}`, params)
//   },
//   post: (modulo: Partial<Modulo>): Promise<Usuario[]> =>
//     requests.post('/modulos', modulo),
//   delete: (id: string | number): Promise<Usuario[]> =>
//     requests.delete(`/modulos/${id}`),
// }

// const Cursos = {
//   get: ({ query, id }: { query?: IQueryParams, id?: number }): Promise<ICounter<Curso>> =>
//     requests.get(`/cursos/${id || ''}`, { ...(query || {}) }),
//   post: (curso: Partial<Curso>): Promise<Curso> =>
//     requests.post(`/cursos`, curso),
//   delete: (id: string): Promise<Curso> =>
//     requests.delete(`/cursos/${id}`),
// }

const Paginas = {
  getByModulo: async (moduloId: number): Promise<ConteudoPaginaModel[]> =>
    await requests.get(`/conteudos-paginas/modulo/${moduloId}`),
  createOrUpdate: async (
    paginas: ConteudoPaginaModel[],
    moduloId: number
  ): Promise<ConteudoPaginaModel[]> =>
    await requests.post('/conteudos-paginas', { paginas, moduloId }),
  getTotalItensPaginas: async (paginas: string[]): Promise<Array<{ id: string; total: number }>> =>
    await requests.post('/conteudos-paginas/get-totais', { paginas }),
}

const Questoes = {
  getById: async (questaoId: number): Promise<QuestaoModel> =>
    await requests.get(`/app/questoes/${questaoId}`),
  createRespostaRelacionarItens: async (questaoId, respostasInformadas): Promise<RespostaModel> =>
    await requests.post('/app/questoes/resposta/relacionar-itens', {
      questaoId,
      respostasInformadas,
    }),
  createRespostaMultiplaEscolha: async ({
    questaoId,
    alternativaId,
    resposta,
  }): Promise<RespostaModel> =>
    await requests.post('/app/questoes/resposta/multipla-escolha', {
      questaoId,
      alternativaId,
      resposta,
    }),
}

const Foruns = {
  getAll: async (query?: IQueryParams): Promise<ICounter<ForumModel>> =>
    await requests.get('/usuarios/foruns/tutores', query),
  getAllByCurso: async (query: any): Promise<any> => await requests.get('/foruns', { ...query }),
  getForunsMensagensPendentes: async (
    query?: IQueryParams
  ): Promise<ICounter<ForumMensagemModel>> =>
    await requests.get('/foruns/mensagens/pendentes', query),
  getOne: async (forumId: number): Promise<ForumModel> => await requests.get(`/foruns/${forumId}`),
  getForunsMensagensByForum: async (forumId: number): Promise<ForumMensagemModel[]> =>
    await requests.get(`/foruns/mensagens/${forumId}`),
  aprovarMensagem: async (forumMensagemId: number): Promise<boolean> =>
    await requests.put('/foruns/mensagem/aprovar', { forumMensagemId }),
  aprovarForum: async (forumId: number): Promise<boolean> =>
    await requests.put('/foruns/aprovar', { forumId }),
  rejeitar: async (forumMensagemId: number): Promise<boolean> =>
    await requests.put('/foruns/mensagem/rejeitar', { forumMensagemId }),
}

const ForunsMensagens = {
  createOrUpdate: async (forumMensagem: ForumMensagemModel): Promise<ForumMensagemModel> =>
    await requests.post('/foruns/mensagens/', forumMensagem),
}

const Progresso = {
  getByAluno: async (alunoId: number): Promise<ICounter<ProgressoModel>> =>
    await requests.get(`/progressos/aluno/${alunoId}`),
  create: async ({ conteudoPaginaId, conteudoItemId, porcentagem }): Promise<any> =>
    await requests.post('/progressos', { conteudoPaginaId, conteudoItemId, porcentagem }),
  getTempoEstudo: async (): Promise<{
    periodoRestanteHoje
    periodoRestanteSessaoAtual
    periodoRestantePausa
  }> => await requests.get('/progressos/tempo-estudo'),
}

const Chat = {
  getChatsByUsuarioLogado: async (): Promise<ICounter<ChatModel>> => await requests.get('/chats'),
  getChatById: async (chatId: number): Promise<ChatModel> => await requests.get(`/chats/${chatId}`),
  getMessagesByChatId: async (chatId: number): Promise<ChatMensagemModel[]> =>
    await requests.get(`/chats/mensagens/${chatId}`),
  getAlunos: async (): Promise<ICounter<UsuarioModel>> => await requests.get('/chats/alunos'),
  sendMessage: async (mensagem: string, chatId: number): Promise<ChatMensagemModel> =>
    await requests.post('/chats/mensagens', { mensagem, chatId }),
  loadOrCreateChat: async (tutorId: number): Promise<ChatModel> =>
    await requests.post('/chats', { tutorId }),
  updateMensagensVisualizadas: async (chatId: number): Promise<boolean> =>
    await requests.put('/chats/mensagem/visualizada', { chatId }),
}

// getMensagens(chatId: number, idMaisAntigo?: number): Promise<any> {
//   return this.api.get(`chats/mensagens/${chatId}`, { idMaisAntigo }).toPromise()
// }

// export const AxiosComponent = (): any => {
//   const { setIsLoading, setToken, setErrorMessage } = useContext<GlobalStoreValues>(GlobalStoreCtx)
// _setIsLoading = setIsLoading
//   _setToken = setToken
//   _setError = setErrorMessage
//   return null
// }

const Tutoria = {
  getTutoresByCurso: async (cursoId: number): Promise<UsuarioModel[]> =>
    await requests.get('/usuarios/perfil/TUTOR', { cursoId }),
  getAlunosByCurso: async (cursoId: number): Promise<ICounter<UsuarioModel>> =>
    await requests.get(`/cursos/${cursoId}/papel/ALUNO`),
}

const Conteudo = {
  getConteudoByModulo: async (moduloId: number): Promise<ConteudoPaginaModel[]> =>
    await requests.get(`/conteudos-paginas/modulo/${moduloId}`),
  getPosicaoAlunoByModulo: async (moduloId: number): Promise<any> =>
    await requests.get(`/progressos/aluno/modulo/${moduloId}/recente`),
}

const ValidacaoFacial = {
  getSerpro: async (): Promise<any> => await requests.get('/validacoes/serpro'),
  getUltima: async (): Promise<any> => await requests.get('/validacoes/ultima'),
}

const Certificado = {
  getCertificadoValidacao: (certificadoId): Promise<{ pdf; matricula }> =>
    requests.get(`/certificados/validacao/${certificadoId}`),
}

export default {
  Usuarios,
  Files,
  // UsuariosCursos,
  Modulos,
  // Cursos,
  Paginas,
  Questoes,
  Foruns,
  ForunsMensagens,
  Progresso,
  Chat,
  Conteudo,
  ValidacaoFacial,
  Tutoria,
  Avaliacoes,
  Certificado,
}
